import React from "react";
import DataMonitoring from "./Main/Data Monitoring/data-monitoring";
import Header from "./Main/header";
import PullRequest from "./Main/Pull Request/pull-request";
import Orchestrator from "./Main/Orchestrator/Orchestrator";
import Instruction from "./Main/Instruction/Instruction";
import AdminPage from "./Main/AdminPage/adminPage";
import { useUserRole,UserRoleProvider } from './Main/LoginPage/UserRoleContext';
import "./App.css";
import AppRegistrationMonitor from "./Main/AppRegMonitor/app-reg-monitor";
import RepoDefectsView from "./Main/DefectsBoard/repo-defects-view";
import QualityResultView from "./Main/QualityBoard/quality-results-view";
import { NotifyProvider } from "./features/useNotify";



function AppContent({ selectedComponent }) {  
  // Use the custom hook to get the user role and loading state  
  const { userRole, isLoading } = useUserRole();  

  if (isLoading) {  
    return <div>Loading...</div>; // Render loading indicator  
  }  
  
  if (userRole === null) {  
    return <div>Unauthorized</div>; // Render unauthorized message  
  }  
  
  // Render the selected component based on the user role  
  switch (selectedComponent) {  
    case "vulnerabilities":  
      return <DataMonitoring />;  
    case "pullRequests":  
      return <PullRequest />; 
    case "orchestrator":
      return <Orchestrator />; 
    case "instruction":
      return <Instruction />;
    case "adminPage":  
      return userRole === 'admin' ? <AdminPage /> : <div>Unauthorized</div>; 
    case "appRegistrationMonitor":
        return <AppRegistrationMonitor />;
    case "repoDefectsView":
        return <RepoDefectsView />;
    case "qualityResultView":
        return <QualityResultView/>
    default:  
      return null; // Or handle the default case as needed  
  }  
} 

function App() {
  const [selectedComponent, setSelectedComponent] = React.useState("instruction");

  return (
    <NotifyProvider>
    <UserRoleProvider>
    <div className="App">
      <header className="App-header">
        <Header 
          selectedComponent={selectedComponent}
          onComponentChange={setSelectedComponent}
        />
      </header>
      <div className={`${selectedComponent === 'instruction' ? 'instruction-content' : 'App-content'}`}>
        <AppContent selectedComponent={selectedComponent} />
      </div>
    </div>
    </UserRoleProvider>
    </NotifyProvider>
  );
}

export default App;  
