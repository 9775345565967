import { useState,useEffect } from 'react';
import {
  Box, Container, Typography
} from '@mui/material';
import { useUserRole } from '../LoginPage/UserRoleContext';

    
const QualtyResultView = () => {  
 const [data,setData]=useState({});
 const {userName}=useUserRole();
 
 useEffect(() => {
  async function fetchData() {
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "QualityResultAssetPathsFetchService";
    const response = await fetch(fetchUrl, {
      // headers: {  
      //     'Authorization': `Bearer ${accessToken}`, 
      // }   
    });
    if (response && response.status === 200) {
      const responseData = await response.json();
      console.log(responseData);
      setData(responseData)
    }
  }
  fetchData();
}, []);
  
  return (    
    <>    
      <Box display="flex" flexDirection="column" minHeight="100vh" >    
        <Box bgcolor="#EEF4FF" pt={6} pb={3} px={10} sx={{margin:0}}>  
          <Container maxWidth={false} >    
            <Typography variant="h5" fontWeight={700} fontSize="24px" gutterBottom sx={{color:'#00184D'}} >  
              Welcome {userName}
            </Typography>  
            <Typography   gutterBottom sx={{color:'#00184D'}} > 
              Quality Test Results Blobs / Stored Reports
            </Typography>            
          </Container>  
        </Box>
        <ul>
          <li><a href="QER/10302024143338/index.html" rel="noreferrer" target="_blank">BCA-TestAutomation-UI-Test | 10302024143338</a></li>
          <li><a href="QER/10302024145613/index.html" rel="noreferrer" target="_blank">BCA-TestAutomation-UI-Test | 10302024145613</a></li>
        </ul>
        <Box px={10} pb={10} sx={{margin:0}}>
          <Container maxWidth={false}>  
            {Object.keys(data).map((item, index) => (  
              <Box p={2} key={index}>  
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  Container : {data[item].StorageContainerName}
                </Typography>
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  Folder : {data[item].StorageFolderName}
                </Typography>  
                Reports
                {/* <ul>  
                  {data[item].StorageFilePaths.map((item, idx) => (
                    <li key={idx}><a href={item.AssetIndexPath} rel='noreferrer' target='_blank'>{item.AssetDisplayName}</a></li>
                  ))}  
                </ul>   */}          
              </Box>  
            ))}  
          </Container>  
        </Box>
      </Box>
    </>  
  );  
};  
    
export default QualtyResultView;  
