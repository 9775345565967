import React, { useState, useEffect } from 'react';
import {
  Paper, Table, TableContainer, TableBody, TableCell, TableHead, TableRow,
  Box, Collapse, IconButton, Button, Dialog, DialogActions, DialogContent, Link,
  DialogContentText, DialogTitle, TextField, Fab, Tooltip, ToggleButtonGroup, ToggleButton,
  Stack, Chip, Avatar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import LoadingButton from '@mui/lab/LoadingButton';
import SelectSmall from '../Data Monitoring/selectFn';
import BasicTextFields from '../Data Monitoring/textfield';
import { useUserRole } from '../LoginPage/UserRoleContext';
import { useNotify } from '../../features/useNotify';
import AppRegProjectStatsPie from './app-reg-project-status';


const AppRegistrationMonitor = () => {

  const { userEmail } = useUserRole();
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedRow, setExpandedRow] = useState(-1);
  const [sortConfig, setSortConfig] = useState({ key: 'AppRegName', direction: 'desc' });
  const [selectedProject, setSelectedProject] = useState('');
  const uniqueProjects = Array.from(new Set(tableData.map((row) => row.ProjectCode)));
  const [currentRegDetails, setCurrentRegDetails] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [openAppRegEntryModal, setOpenAppRegEntryModal] = useState(false);
  const [selectedProjectCode, setSelectedProjectCode] = useState('');
  const [isValidationProgress, setIsValidationProgress] = useState(false);
  const [isPageLoding, setIsPageLoding] = useState(false);
  const [inEmail, setInEmail] = useState(userEmail);
  const [inRegId, setInRegId] = useState('');
  const [isRegInputError, setIsRegInputError] = useState({ isError: false, message: '' });
  const [isValidAppRegEntry, setIsValidAppRegEntry] = useState(false);
  const [isValidReqEmail, setIsValidReqEmail] = useState({ isError: false, message: '' });
  const [expiryDays, setExpiryDays] = useState('reset');
  const Notify = useNotify();
  const [pieProjectStats, setPieProjectStats] = useState({
    seven:[],
    fifteen:[],
    thirty:[],
    sixty:[],
    ninty:[]
  });
  const [pieChartMarker, setPieChartMarker] = useState([]);
  const [registeredAppIdCount, setRegisteredAppIdCount] = useState(0); 

  const currentProjectCodes = [
    "Select One",
    "OIA",
    "SMA",
    "DevOps",
    "ChartAl",
    "Housecalls",
    "GAI",
    "PASS",
    "HCA",
    "CSC-PA",
    "BCA",
    "ContactCenter-Al"
  ]

  const sortedRows = React.useMemo(() => {
    if (sortConfig.key === null) {
      return tableData;
    }
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [tableData, sortConfig]);

  // const handleSort = (key) => {
  //   let direction = 'asc';
  //   if (sortConfig.key === key && sortConfig.direction === 'asc') {
  //     direction = 'desc';
  //   }
  //   setSortConfig({ key, direction });
  // };

  const handleRowClick = async (rowIndex) => {
    const regId = filteredRows[rowIndex].AppRegId;
    setCurrentRegDetails([]);
    if (expandedRow === rowIndex) {
      setExpandedRow(-1);
      setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [rowIndex]: false }));
    } else {
      setExpandedRow(rowIndex);
      setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [rowIndex]: true }));

      // Filter Out Credentials for the selected App Registration 
      try {
        const data = tableData.map((row) => row.AppRegId === regId ? row.Credentials : []).flat();

        if (data && Array.isArray(data)) {
          setCurrentRegDetails(data);
        } else {
          setCurrentRegDetails([]);
        }
      } catch (error) {
        console.error("Error Filtering Credentials : ", error);
      } finally {
        setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [rowIndex]: false }));
      }
    }
  };

  const handleSearchChange = (event) => {
    setSortConfig('AppRegName', 'desc');
    setSearchQuery(event.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return sortedRows.filter((row) =>
      (selectedProject === '' || row.ProjectCode === selectedProject) &&
      ((row.AppRegName && row.AppRegName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (row.url && row.url.toLowerCase().includes(searchQuery.toLowerCase())))
    );
  }, [sortedRows, searchQuery, selectedProject]);


  const handleAddNewAppRegClick = () => {
    setInEmail(userEmail);
    setInRegId('');
    setIsRegInputError({ isError: false, message: '' });
    setIsValidReqEmail({ isError: false, message: '' });
    setIsValidAppRegEntry(false);
    setOpenAppRegEntryModal(true);
  }

  const handleAppRegEntryModalClose = () => {
    setInEmail('');
    setInRegId('');
    setIsRegInputError({ isError: false, message: '' });
    setIsValidReqEmail({ isError: false, message: '' });
    setIsValidAppRegEntry(false);
    setOpenAppRegEntryModal(false);
  }

  const checkInputFields = () => {
    if (inRegId === '' || inRegId.length < 35) {
      Notify("Application Registration ID is Required and Minimum Length of 35", 'error');
      return false;
    }

    if (selectedProjectCode === 'Select One' || selectedProjectCode === '') {
      Notify("Select Project Code", 'error');
      return false;
    }
    return true;
  }

  const handleAppRegEntryModalSave = async () => {
    // const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
    // if (!accessToken) {  
    //     console.error('No accessToken found in session storage');  
    //     return;  
    // }
    if (checkInputFields() === false) return;
    const data = { "AppRegId": inRegId, "ProjectCode": selectedProjectCode, "RequestedBy": inEmail };
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "AppRegistrationDetailsStoreService";
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${accessToken}`, 
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData && responseData.IsSaved === true) {
      Notify("Apllication Registration ID -" + inRegId + "\nSaved Successfully !", 'success');
      setOpenAppRegEntryModal(false);
      
      // RELOAD | All AppID(s) after Save
      handleExpirySelection(null, "reset");
    } else {
      Notify("Apllication Registration ID -" + inRegId + "\nSave Failed !", 'error');
      setOpenAppRegEntryModal(false);
    }
  }

  const handleAppRegEntryModalValidate = async () => {
    // const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
    // if (!accessToken) {  
    //     console.error('No accessToken found in session storage');  
    //     return;  
    // }
    if (inRegId === '' || inRegId.length < 35) {
      setIsRegInputError({ isError: true, message: 'Application Registration, Required Length is 35' });
      return;
    } else {
      setIsRegInputError({ isError: false, message: '' });
    };
    setIsValidationProgress(true);
    const data = { "AppRegId": inRegId };
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "AppRegistrationDetailsValidityService";
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${accessToken}`, 
      },
      body: JSON.stringify(data),
    });
    if (response && response.status === 200) {
      const responseData = await response.json();
      if (responseData && responseData.IsAppRegValid === false && responseData.IsDbEntry === true) {
        Notify("Valid ! \n" + responseData.Message, 'error');
        setIsValidAppRegEntry(false);
      } else if (responseData && responseData.IsAppRegValid === true) {
        Notify("Valid ! \nApplication Registration Found. \nName : " +
          responseData.AppRegName + " and " + responseData.AppRegCredentialsCount +
          " Credentials in It. Please Proceed to Save", "success");
        setIsValidAppRegEntry(true);
      }
      else {
        Notify("In Valid ! \nApplication Registration Not Found, Please Check and Try Again !", 'error');
        setIsValidAppRegEntry(false);
      }
    }
    setIsValidationProgress(false);
  }

  const handleAppRegIdKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleAppRegEntryModalValidate();
    }
    if (inRegId === '' || inRegId.length < 35) {
      setIsRegInputError({ isError: true, message: 'Application Registration, Required Length is 35' });
      setIsValidAppRegEntry(false);
      return;
    } else {
      setIsRegInputError({ isError: false, message: '' });
      return;
    };
  }

  const handleReqEmailKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleAppRegEntryModalValidate();
    }
    if (inEmail === '' || !inEmail.includes('@optum') || !inEmail.includes('.')) {
      setIsValidReqEmail({ isError: true, message: 'Invalid Email Format, Must be Optum Email' });
      setIsValidAppRegEntry(false);
      return;
    } else {
      setIsValidReqEmail({ isError: false, message: '' });
      return;
    };
  }

  const handleExpirySelection = async (event, selectValue) => {
    setIsPageLoding(true);
    setExpiryDays(selectValue);
    if (selectValue === 'reset') {
      setTableData([]);
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "AppRegistrationDetailsFetchService";
      const response = await fetch(fetchUrl);
      if (response && response.status === 200) {
        const responseData = await response.json();
        Notify("All AppID(s), Reset Filter", 'info');
        setTableData(responseData);
      }
    } else {
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "AppRegistrationDetailsFetchService?expiryDays=" + selectValue;
      const response = await fetch(fetchUrl);
      if (response && response.status === 200) {
        const responseData = await response.json();
        if (responseData && responseData.length > 0) {
          Notify(responseData.length + " AppID(s) Found within " + selectValue + " Expiry Days", 'info');
          setTableData(responseData);
        } else {
          Notify("No AppID(s) Found within " + selectValue + " Expiry Days", 'info');
          setTableData([]);
        }
      }
    }
    setIsPageLoding(false);
  }

  const setupPieChartMarkerLegend = (projects) => {
    const colorCodes = ["", "#8dd3c7", "#ffffb3", "#bebada", "#fb8072", "#80b1d3", "#fdb462"];
    var determinant = 0;
    let projectMarkerMap= new Map();
    projects.SevenDaysExpiryCredProjects.forEach((row) => {
      if(!projectMarkerMap.has(row.ProjectName)){
        determinant++;
        projectMarkerMap.set(row.ProjectName, colorCodes[determinant]);
      }
    });

    projects.FifteenDaysExpiryCredProjects.forEach((row) => {
      if(!projectMarkerMap.has(row.ProjectName)){
        determinant++;
        projectMarkerMap.set(row.ProjectName, colorCodes[determinant]);
      }
    });

    projects.ThirtyDaysExpiryCredProjects.forEach((row) => {
      if(!projectMarkerMap.has(row.ProjectName)){
        determinant++;
        projectMarkerMap.set(row.ProjectName, colorCodes[determinant]);
      }
    });

    projects.SixtyDaysExpiryCredProjects.forEach((row) => {
      if(!projectMarkerMap.has(row.ProjectName)){
        determinant++;
        projectMarkerMap.set(row.ProjectName, colorCodes[determinant]);
      }
    });

    projects.NintyDaysExpiryCredProjects.forEach((row) => {
      if(!projectMarkerMap.has(row.ProjectName)){
        determinant++;
        projectMarkerMap.set(row.ProjectName, colorCodes[determinant]);
      }
    });

    setPieChartMarker(projectMarkerMap);
  }
  
  // const fetchPieStats = async() => {
  //   // const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
  //   // if (!accessToken) {  
  //   //     console.error('No accessToken found in session storage');  
  //   //     return;  
  //   // }  
  //   const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
  //   const fetchUrl = baseUrl + "AppRegistrationPieReportService";
  //   const response = await fetch(fetchUrl, {
  //     // headers: {  
  //     //     'Authorization': `Bearer ${accessToken}`, 
  //     // }   
  //   });
  //   if (response && response.status === 200) {
  //     const responseData = await response.json();
  //     if(responseData && responseData.length !== 0){
  //       setupPieChartMarkerLegend(responseData);
  //       setPieProjectStats({
  //         seven:responseData.SevenDaysExpiryCredProjects,
  //         fifteen:responseData.FifteenDaysExpiryCredProjects,
  //         thirty:responseData.ThirtyDaysExpiryCredProjects,
  //         sixty:responseData.SixtyDaysExpiryCredProjects,
  //         ninty:responseData.NintyDaysExpiryCredProjects});
  //     } 
  //   }
  // }

  useEffect(() => {
    async function normalizeData() {
      // const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
      // if (!accessToken) {  
      //     console.error('No accessToken found in session storage');  
      //     return;  
      // }  
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "AppRegistrationPieReportService";
      const response = await fetch(fetchUrl, {
        // headers: {  
        //     'Authorization': `Bearer ${accessToken}`, 
        // }   
      });
      if (response && response.status === 200) {
        const responseData = await response.json();
        if (responseData && responseData.length !== 0) {
          setupPieChartMarkerLegend(responseData);
          setPieProjectStats({
            seven: responseData.SevenDaysExpiryCredProjects,
            fifteen: responseData.FifteenDaysExpiryCredProjects,
            thirty: responseData.ThirtyDaysExpiryCredProjects,
            sixty: responseData.SixtyDaysExpiryCredProjects,
            ninty: responseData.NintyDaysExpiryCredProjects
          });
        }
      }
    }
    async function fetchData() {
      setIsPageLoding(true);
      // const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
      // if (!accessToken) {  
      //     console.error('No accessToken found in session storage');  
      //     return;  
      // }  
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "AppRegistrationDetailsFetchService";
      const response = await fetch(fetchUrl, {
        // headers: {  
        //     'Authorization': `Bearer ${accessToken}`, 
        // }   
      });
      if (response && response.status === 200) {
        const responseData = await response.json();
        setRegisteredAppIdCount(responseData.length);
        setTableData(responseData);
        setIsPageLoding(false);
      }
    }
    normalizeData();
    fetchData();
  }, []);

  return (
    <>
      <div style={{ marginTop: '5px', marginLeft: '60px', marginRight: '60px' }}>
        <div style={{ display: "flex", marginLeft: "-0.5%", gap: "3%" }}>
          <SelectSmall projects={uniqueProjects} onProjectChange={setSelectedProject} />
          <BasicTextFields onSearchChange={handleSearchChange} />
          <Box sx={{ '& > :not(style)': { m: 1 } }} >
            Expires In
            <ToggleButtonGroup
              exclusive
              value={expiryDays}
              onChange={handleExpirySelection}
              aria-label="text formatting"
              color="primary"
            >
              <ToggleButton value="reset">
                <ThreeSixtyIcon />
              </ToggleButton>
              <ToggleButton value="7">
                7
              </ToggleButton>
              <ToggleButton value="15">
                15
              </ToggleButton>
              <ToggleButton value="30">
                30
              </ToggleButton>
              <ToggleButton value="60">
                60
              </ToggleButton>
              <ToggleButton value="90">
                90
              </ToggleButton>
            </ToggleButtonGroup>
            Days
          </Box>
          <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Tooltip title="Add Application's ID to Monitor" arrow placement="right">
              <Fab color="primary" aria-label="add" onClick={handleAddNewAppRegClick} size='small'>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Box>
          {
            isPageLoding ? (
              <Box sx={{ '& > :not(style)': { m: 1, marginX: 20, color: 'success.main' } }}>
                <Fab color="inherit" variant='extended' aria-label="load" size='small'>
                  <LoadingButton loading />
                  Loading
                </Fab>
              </Box>
            ) : null
          }
        </div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={3} variant="outlined" square={false}>
          <div style={{ display: "flex", gap: "0.1%", height: "250px" }}>
            {pieProjectStats && pieProjectStats.seven.length > 0 ?
              (<AppRegProjectStatsPie statData={pieProjectStats.seven} pieName="Expires in 7 Days" />) :
              null
            }
             {pieProjectStats && pieProjectStats.fifteen.length > 0 ?
              (<AppRegProjectStatsPie statData={pieProjectStats.fifteen} pieName="Expires in 15 Days"/>) :
              null
            }
             {pieProjectStats && pieProjectStats.thirty.length > 0 ?
              (<AppRegProjectStatsPie statData={pieProjectStats.thirty} pieName="Expires in 30 Days"/>) :
              null
            }
             {pieProjectStats && pieProjectStats.sixty.length > 0 ?
              (<AppRegProjectStatsPie statData={pieProjectStats.sixty} pieName="Expires in 60 Days"/>) :
              null
            }
             {pieProjectStats && pieProjectStats.ninty.length > 0 ?
              (<AppRegProjectStatsPie statData={pieProjectStats.ninty} pieName="Expires in 90 Days"/>) :
              null
            }
          </div>
          <div style={{ display: "flex", gap: "0.1%", justifyContent: "center"}}>
            <Chip size='small' avatar={<Avatar sx={{ bgcolor: "white" }}><b>{registeredAppIdCount}</b></Avatar>} label="Registered AppID(s)" />
            <Stack direction="row" spacing={1}>
              {Array.from(pieChartMarker.keys()).map((projectName) =>
                (<Chip size='small' avatar={<Avatar sx={{ bgcolor: pieChartMarker.get(projectName) }}> </Avatar>} key={projectName} label={projectName} />))
              }
            </Stack>
          </div>
        </Paper>
        <br />
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
          <TableContainer sx={{ maxHeight: '60%' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: 'bold' }}>App Registration Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>App Registration Link</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', cursor: 'pointer' }}>Certificate Stats</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row, index) => (
                  <>
                    <TableRow
                      key={row.AppRegId}
                      onClick={() => handleRowClick(index)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        {loadingStates[index] ? (<LoadingButton
                          loading
                          size="small"
                        >
                          <ExpandMoreIcon />
                        </LoadingButton>
                        ) : (<IconButton
                          aria-label="expand row"
                          size="small"
                          sx={{
                            transform: expandedRow === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>)}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.AppRegName}</TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        <Link href={row.AppRegPortalLink} onClick={(event) => { event.stopPropagation(); }} target="_blank" rel="noopener noreferrer">
                          {row.AppRegId}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                        <label style={{ color: 'green', marginLeft: '5px' }}>Active</label> : {row.Active} <br />
                        <label style={{ color: 'red', marginLeft: '5px' }}>Expired</label> : {row.Expired}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1, width: '100%' }}>
                            <Table size="small" aria-label="severity levels table">
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell sx={{ fontWeight: 'bold' }} >Description / Name</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }} >Start Date</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }} >End Date</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }} >Expires In</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', cursor: 'pointer' }}>Status</TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {expandedRow === index && currentRegDetails.map((reg, regIndex) => (
                                  <TableRow key={reg.KeyId}>
                                    <TableCell />
                                    <TableCell component="th" scope="row">
                                      {reg.DisplayName}
                                    </TableCell>
                                    <TableCell>
                                      {reg.StartDate}
                                    </TableCell>
                                    <TableCell>
                                      {reg.EndDate}
                                    </TableCell>
                                    <TableCell>{reg.ExpiresIn} Days</TableCell>
                                    {
                                      reg.Status === 'Active' ? (
                                        <TableCell sx={{ color: 'green' }}>{reg.Status}</TableCell>
                                      ) : (
                                        <TableCell sx={{ color: 'red' }}>{reg.Status}</TableCell>
                                      )
                                    }
                                    <TableCell />
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody >
            </Table>
          </TableContainer>
        </Paper>
      </div>

      {/* Application Registration Modal Window */}
      <Dialog open={openAppRegEntryModal} static onClose={handleAppRegEntryModalClose}>
        <DialogTitle>
          <Box bgcolor="#FAF8F2" paddingY={2} paddingX={1}>
            <h7>Application Registration Monitoring - Intake</h7>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box paddingY={1} paddingX={1}>
              Add your <b>Application (client) ID</b> to Monitor, It will get Synced with Credentials.
              Please fill the below details and Link to <b>Correct Project</b>.
            </Box>
          </DialogContentText>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh" tyle={{ marginTop: '2px', }}>
            <Paper elevation={3} style={{ width: '100%', marginBottom: '5px', borderRadius: '7px' }} square={false}>
              <SelectSmall required projects={currentProjectCodes} onProjectChange={setSelectedProjectCode} />

              <TextField required id="RequestedBy" type='email' name="RequestedBy"
                label="Requested By" size="small" value={inEmail}
                onChange={e => { setInEmail(e.target.value) }} style={{ marginTop: '10px', width: '59%', }}
                error={isValidReqEmail.isError} helperText={isValidReqEmail.message} onKeyUp={handleReqEmailKeyUp} />

              <TextField required id="AppRegId" type='text' name="AppRegId"
                label="Application Registration ID" size="small" value={inRegId}
                onChange={e => { setInRegId(e.target.value) }} style={{ margin: '7px', width: '97%', }}
                error={isRegInputError.isError} helperText={isRegInputError.message} onKeyUp={handleAppRegIdKeyUp} />
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='secondary' size='small' onClick={handleAppRegEntryModalClose}>
            Cancel
          </Button>
          {
            isValidAppRegEntry ? (
              <Button variant='outlined' color='success' size='small' onClick={handleAppRegEntryModalSave}>
                Save
              </Button>
            ) : (
              isValidationProgress ? (<LoadingButton loading size="small"></LoadingButton>) :
                (<Button variant='outlined' color='success' size='small' onClick={handleAppRegEntryModalValidate}>
                  Validate
                </Button>)
            )
          }
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppRegistrationMonitor;