import React, { useEffect, useState } from 'react';  
import { FormControlLabel, Checkbox, TextField, Button, FormGroup, Paper,Box,Typography } from '@mui/material'; 
import templateConfig from './template.config.json';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../features/alertSlice';
import { useUserRole } from '../LoginPage/UserRoleContext';


const initialFormState = (userEmail) => {  
  return templateConfig.Resource_list.reduce((acc, resource) => {  
    acc[resource.Template_name.replace(/-/g, '').replace('.tf', '')] = false;  
    return acc;  
  }, {  
    ownerName: '',  
    ownerEmail: userEmail, // Set initial value to userEmail  
    pocName: '',  
    pocShortName: '',  
    pocEndDate: '',  
  });  
};  
  

const Orchestrator = () => {  

  const dispatch=useDispatch();
  const {userEmail}=useUserRole();
  const [formState, setFormState] = useState(() => initialFormState(userEmail));
  
  useEffect(()=>{
    if(!templateConfig || !templateConfig.Resource_list || templateConfig.Resource_list.length===0){
        setFormState({});
    }
  },[]);


  const handleInputChange = (event) => {  
    const { name, value, type, checked } = event.target;  
    setFormState({  
      ...formState,  
      [name]: type === 'checkbox' ? checked : value,  
    });  
  };  
  
  const handleSubmit = async(event) => {  
    event.preventDefault();  

    // Create an array of selected resources based on the checkboxes that are true  
    const selectedResources = templateConfig.Resource_list.filter(resource => {  
        const checkboxName = resource.Template_name.replace(/-/g, '').replace('.tf', '');  
        return formState[checkboxName];  
    });  
    
    // Construct the output object with the Resource_list and other form data  
    const outputData = {  
        Resource_list: selectedResources,  
        ownerName: formState.ownerName, 
        ownerEmail:formState.ownerEmail,
        pocName:formState.pocName,
        pocShortName:formState.pocShortName,
        pocEndDate:formState.pocEndDate
    };  

    console.log('Form submitted with the following state:', outputData);
    try{
        const accessToken = sessionStorage.getItem('accessToken');  
        if (!accessToken) {  
          console.error('No accessToken found in session storage');  
          return;  
        }

        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl+"CreateTerraformFile";
        const response=await fetch(fetchUrl,{
            method:'POST',
            headers:{
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type':'application/json',
            },
            body:JSON.stringify(outputData),
        });

        if(response.ok){
          dispatch(showAlert({
            message:"Request successfully raised",
            severity:"success"
          }));

          setFormState(initialFormState(userEmail));
        }else if (response.status === 409) {
          const errorText = await response.text();   
          dispatch(showAlert({  
            message: `Error: ${errorText}`,  
            severity: "warning"  
          }));  
        } else{
          const errorText=response.json();
          dispatch(showAlert({
            message:`Error: ${errorText}`,
            severity:"error"
          }));
            console.error("HTTP error:",response.status);
        }
    }catch(error){
        console.error("Request error:",error);
    }
  };  

  const textFieldStyle={
    marginBottom:'20px',
    width:'63%',
  }
  const textFieldHeaderStyle={
    marginBottom:'0px'
  }
  const RequiredAsterisk = () => (  
    <span style={{ color: 'red' }}>*</span>
  );  
  
  return (  
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">  
      <Paper elevation={3} style={{ maxWidth: '88%', width: '100%' ,marginBottom:'50px'}}>  
        <Box bgcolor="#FAF8F2" paddingY={1} paddingX={5}>  
          <h1>Requestor Intake Form</h1>  
        </Box>
        <Box style={{ padding: '32px' }}>  
        <form style={{margin: '16px'}} onSubmit={handleSubmit}>  
          <FormGroup>
          <Typography variant="body1" gutterBottom>  
            {templateConfig.explanatoryText}  
          </Typography> 
          <h4 style={{ marginTop: '10px' }}>POC/Sandbox Azure Environment</h4>  
            {templateConfig.Resource_list.map((resource,index)=>{
                const checkboxName=resource.Template_name.replace(/-/g,'').replace('.tf','');
                return(
                    <FormControlLabel  
                        key={index}
                        control={  
                        <Checkbox  
                            name={checkboxName}  
                            checked={formState[checkboxName]}  
                            onChange={handleInputChange}  
                        />  
                        }  
                        label={resource.resource_name}
                    />
                );
            })}
             

            <h4 style={textFieldHeaderStyle}>Owner Name <RequiredAsterisk /></h4>  
            <TextField style={textFieldStyle}
              name="ownerName"
              value={formState.ownerName}
              onChange={handleInputChange}
              margin='normal'
              required
            />
            <h4 style={textFieldHeaderStyle}>Owner Email Address <RequiredAsterisk /></h4>  
            <TextField style={textFieldStyle}
              name="ownerEmail"
              type="email"
              value={formState.ownerEmail}
              onChange={handleInputChange}
              margin='normal'
              required
            />
            <h4 style={textFieldHeaderStyle}>POC Name <RequiredAsterisk /></h4>  
            <TextField style={textFieldStyle}
              name="pocName"
              value={formState.pocName}
              onChange={handleInputChange}
              margin='normal'
              required
            />
            <h4 style={{marginBottom:'0px'}}>POC Short Name <RequiredAsterisk /></h4>
            <p style={{marginTop:'0px',marginBottom:'0px'}}>3-5 characters</p>
            <TextField style={textFieldStyle}
              name="pocShortName"
              value={formState.pocShortName}
              onChange={handleInputChange}
              margin='normal'
              inputProps={{maxLength:5 ,style:{textTransform: 'lowercase'} }}
              required
            />
            <h4 style={textFieldHeaderStyle}>POC End Date <RequiredAsterisk /></h4> 
            <TextField style={{marginBottom:'20px', width:'20%',}}
              name="pocEndDate"
              type='date'
              value={formState.pocEndDate}
              onChange={handleInputChange}
              margin='normal'
              InputLabelProps={{
                shrink:true
              }}
              required
            />
          </FormGroup>
          <Box textAlign="center" marginTop={2}> 
            <Button variant='contained' sx={{ backgroundColor: '#002677' }} style={{ '&:hover': { backgroundColor: '#001a5a' }, borderRadius: '20px' }}  type='submit'>
              Submit Request
            </Button>
          </Box>
        </form> 
        </Box > 
      </Paper >
    </Box >
  );  
};  
  
export default Orchestrator;  
