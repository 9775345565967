import { createSlice } from "@reduxjs/toolkit";

export const alertSlice=createSlice({
    name:'alert',
    initialState:{
        show:false,
        message:'',
        severity:'success'
    },

    reducers:{
        showAlert:(state,action)=>{
            state.show=true;
            state.message=action.payload.message;
            state.severity=action.payload.severity;
        },
        hideAlert:(state,action)=>{
            state.show=false;
            state.message='';
            state.severity='success';
        },
    },
})

export const {showAlert,hideAlert}=alertSlice.actions;

export default alertSlice.reducer;
