import * as React from 'react';
import Switch from '@mui/material/Switch';

const ControlledSwitches = ({ onToggle }) => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        onToggle(event.target.checked);
    };

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color='warning'
        />
    );
};

export default ControlledSwitches;  
