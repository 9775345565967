import React, { useEffect } from 'react';
import {
  Box, Container, Typography
} from '@mui/material';


const RepoDefectsView = () => {

  
  useEffect(() => {
  }, []);

  return (
    <>
     <Box bgcolor="#EEF4FF" pt={6} pb={3} px={10} sx={{margin:0}}>  
          <Container maxWidth={false} >   
            <Typography variant="h5" fontWeight={700} fontSize="24px" gutterBottom sx={{color:'#00184D',mt:'25px'}} >  
              Defects Monitoring Page (Coming soon)

            </Typography> 
          </Container>  
        </Box>
    </>
  );
};

export default RepoDefectsView;