import * as React from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMsal } from "@azure/msal-react";
import List from '@mui/material/List';  
import ListItemButton from '@mui/material/ListItemButton';  
import ListItemText from '@mui/material/ListItemText';
import { ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';  
import ExpandLess from '@mui/icons-material/ExpandLess';  
import ExpandMore from '@mui/icons-material/ExpandMore';  
import { useUserRole } from '../LoginPage/UserRoleContext';

export default function UserProfileButton() {
    const {instance}=useMsal();
    const [open, setOpen] = React.useState(false);  
    const {userName} = useUserRole();
    const handleClick = () => {  
      setOpen(!open);  
    };  
    
    const handleLogout = () => {
        instance.logoutRedirect({
            // postLogoutRedirectUri:"https://incubator-devops-ui-dev.azurewebsites.net/"
        }).catch(e=>{
            console.error(e);
        });
        setOpen(false);
    };  
    
    return (  
      <List component="nav" >  
        <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon> 
          <ListItemText primary={userName} />  
          {open ? <ExpandLess /> : <ExpandMore />}  
        </ListItemButton>  
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ border: '1px solid #ddd', borderRadius: '4px', backgroundColor: 'white' }}>  
          <List component="div" disablePadding>  
            <ListItemButton sx={{ pl: 4 ,bgcolor:'background.paper', '&:hover': {  bgcolor: '#ADD8E6', }}} 
                onClick={() => handleLogout()}>  
              <ListItemText primary="Logout" /> 
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon> 
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    );
}

