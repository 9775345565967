import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../features/alertSlice';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MyResponsiveLine from './monthlyline';
import MyResponsivePie from './piechart';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BasicPie from './expandablepie';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MyWeeklyLine from './weeklyline';
import ControlledSwitches from './ControlledSwitches';
import SelectSmall from './selectFn';
import BasicTextFields from './textfield';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';  
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {transformDataForChart} from './weeklyline' 
import { transformDataForMonthlyChart } from './monthlyline';
import { useUserRole } from '../LoginPage/UserRoleContext'; 

const DataMonitoring = () => {
  const [tableData, setTableData] = useState([]);
  const [isMonthlyReport, setIsMonthlyReport] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedRow, setExpandedRow] = useState(-1);
  const [sortConfig, setSortConfig] = useState({ key: 'shown_vulnerabilities', direction: 'desc' });
  const [selectedProject, setSelectedProject] = useState('');
  const uniqueProjects = Array.from(new Set(tableData.map((row) => row.project_name || "Unassigned")));
  const [selectedRepoDetails, setSelectedRepoDetails] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [editDialogOpen,setEditDialogOpen]=useState(false);
  const [addDialogOpen,setAddDialogOpen]=useState(false);
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [allWeeklyData, setAllWeeklyData] = useState({});
  const [allMonthlyData, setAllMonthlyData] = useState([]);
  const dispatch=useDispatch();
  const {userRole} = useUserRole(); // Use the hook to access the user role  
  const isAdmin = userRole === 'admin';

  const sortedRows = React.useMemo(() => {  
    if (sortConfig.key === null) {  
      return tableData;  
    }  
    const sortedData = [...tableData].sort((a, b) => {  
      const aValue = sortConfig.key === 'shown_vulnerabilities' ? (a.critical_vulnerabilities + a.high_vulnerabilities) : a[sortConfig.key];  
      const bValue = sortConfig.key === 'shown_vulnerabilities' ? (b.critical_vulnerabilities + b.high_vulnerabilities) : b[sortConfig.key];  
      if (aValue < bValue) {  
        return sortConfig.direction === 'asc' ? -1 : 1;  
      }  
      if (aValue > bValue) {  
        return sortConfig.direction === 'asc' ? 1 : -1;  
      }  
      return 0;  
    });  
    return sortedData;  
  }, [tableData, sortConfig]);  

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (rowIndex) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(-1);
    } else {
      setExpandedRow(rowIndex);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return sortedRows.filter((row) =>
    {
      const projectName = row.project_name || "Unassigned"; 
      return ((selectedProject === '' || projectName === selectedProject) &&
      ((row.repo_name && row.repo_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (row.url && row.url.toLowerCase().includes(searchQuery.toLowerCase()))));
    });
  }, [sortedRows, searchQuery, selectedProject]);

  function UpDownArrowIconComponent({ direction }) {
    const opacityUp = direction === 'asc' ? 1 : 0.3;
    const opacityDown = direction === 'desc' ? 1 : 0.3;

    return (
      <span style={{ marginLeft: '5px' }}>
        <ArrowUpwardIcon fontSize="10px" style={{ opacity: opacityUp, marginRight: '-2px' }} />
        <ArrowDownwardIcon fontSize="10px" style={{ opacity: opacityDown, marginLeft: '-2px' }} />
      </span>
    );
  }

  function ButtonFilled({ row }) {
    return (
      <Box sx={{ marginTop: -6 }}>
        <Stack spacing={2} direction="row">
          <Button variant="contained" size="small" onClick={() => fetchRepoDetails(row.repo_name)}>
            View Complete Details
          </Button>
        </Stack>
      </Box>
    );
  }

  const severityOrder = {  
    CRITICAL: 1,  
    HIGH: 2,  
    MODERATE: 3,  
    LOW: 4  
  };  
    
  const sortBySeverity = (vulnerabilities) => {  
    return vulnerabilities.sort((a, b) => {  
      let rankA = severityOrder[a.severity.toUpperCase()] || 5;  
      let rankB = severityOrder[b.severity.toUpperCase()] || 5;  
      return rankA - rankB;  
    });  
  };  
    
  const fetchRepoDetails = async (repoName) => {  
    const accessToken = sessionStorage.getItem('accessToken');  
    if (!accessToken) {  
      console.error('No accessToken found in session storage');  
      return;  
    }
    const baseUrl = process.env.REACT_APP_API_BASE_URL;  
    const fetchUrl = baseUrl+"getRepoDetails?repo_name="+encodeURIComponent(repoName);

    const response = await fetch(fetchUrl, {  
      headers: {  
          'Authorization': `Bearer ${accessToken}`, 
      }   
    });   
    const data = await response.json();
    const repoDetails = data[0];  
    if (repoDetails.vulnerabilities && Array.isArray(repoDetails.vulnerabilities)) {  
      repoDetails.vulnerabilities = sortBySeverity(repoDetails.vulnerabilities);  
    }  

    setSelectedRepoDetails(repoDetails);  
    setDetailsDialogOpen(true);  
  };  
  

  useEffect(() => {  
    async function fetchData() {  
        const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
        if (!accessToken) {  
            console.error('No accessToken found in session storage');  
            return;  
        }  
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl+"getDailyReport";
        const response = await fetch(fetchUrl, {  
            headers: {  
                'Authorization': `Bearer ${accessToken}`, 
            }   
        });  
        const data = await response.json();  
        setAllWeeklyData(data);  
    }  
    fetchData();  
  }, []);

  const weeklyData = React.useMemo(() => {  
    let filteredData = allWeeklyData;  
    
    if (selectedProject && selectedProject !== 'all') {  
      filteredData = { [selectedProject]: allWeeklyData[selectedProject] || {} };  
    }  
    
    return transformDataForChart(filteredData);  
  }, [selectedProject, allWeeklyData]);


  useEffect(() => {  
    async function fetchData() {  
        const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
        if (!accessToken) {  
            console.error('No accessToken found in session storage');  
            return;  
        }  
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl+"GetMonthlyReport";
        const response = await fetch(fetchUrl, {  
            headers: {  
                'Authorization': `Bearer ${accessToken}`, 
            }   
        });  
        const data = await response.json();  
        setAllMonthlyData(data);  
    }  
    fetchData();  
  }, []);

  const monthlyData = React.useMemo(() => {  
    let filteredData = allMonthlyData;  
    
    if (selectedProject && selectedProject !== 'all') {  
      filteredData = { [selectedProject]: allMonthlyData[selectedProject] || {} };  
    }  
    
    return transformDataForMonthlyChart(filteredData);  
  }, [selectedProject, allMonthlyData]);


  useEffect(() => {  
    async function fetchData() {  
        const accessToken = sessionStorage.getItem('accessToken'); 
        if (!accessToken) {  
            console.error('No accessToken found in session storage');  
            return;  
        }  
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl+"getAllRepo";
        const response = await fetch(fetchUrl, {  
            headers: {  
                'Authorization': `Bearer ${accessToken}`, 
            }   
        });  
        const data = await response.json();  
        setTableData(data);  
    }  
    fetchData();  
  }, []);

  // Add form
  const [addRepo, setAddRepo] = useState({  
    add_project: '',  
    repo_name: '',  
    repo_link: '',
    contributor_list: [], 
  });  
  
  const [addContributor, setAddContributor] = useState({  
    add_contributor_name: '',  
    add_contributor_email: '',  
  });  
  

  const handleAddRepo = (event) => {  
    const { name, value } = event.target;  
    setAddRepo({  
      ...addRepo,  
      [name]: value,
    });
  };

  const handleChangeAddContributor = (event) => {  
    const { name, value } = event.target;  
    setAddContributor(prev => ({  
      ...prev,  
      [name]: value,  
    }));  
  };  
  
  const handleAddContributor = () => {  
    // Add the new contributor to the contributor_list  
    if (addContributor.add_contributor_name && addContributor.add_contributor_email) {  
      setAddRepo(prev => ({  
        ...prev,  
        contributor_list: [...prev.contributor_list, addContributor],  
      }));  
      // Clear the temporary new contributor fields  
      setAddContributor({ add_contributor_name: '', add_contributor_email: '' });  
    } else {  
      // Handle the error case where name or email is missing  
      console.error('Name and email are required to add a contributor.');  
    }  
  };  

  // Function to remove a contributor from the list  
  const handleRemoveContributor = (indexToRemove) => {  
    setAddRepo(prev => ({  
      ...prev,  
      contributor_list: prev.contributor_list.filter((_, index) => index !== indexToRemove),  
    }));  
  };  

  const handleCloseAddDialog=useCallback(()=>{
    setAddDialogOpen(false)
  },[])

  const handleAddSubmit = async (event) => {  
    event.preventDefault(); 

    // Check if the form is valid  
    const form = event.target;  
    if (!form.checkValidity()) {  
      form.reportValidity();  
      return;  
    }  

    console.log('Add Data:', addRepo);  
    try {  
      // Send data to the API (replace 'your-api-endpoint' with the actual endpoint)  
      const accessToken = sessionStorage.getItem('accessToken');
      if (!accessToken) {  
          console.error('No accessToken found in session storage');  
          return;  
      }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl+"addRepo";
      const response = await fetch(fetchUrl, {  
        method: 'POST',  
        headers: {  
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${accessToken}`, 
        },  
        body: JSON.stringify(addRepo),  
      });  
      // Handle the API response  
      if (response.ok) {  
        // Success logic here
        dispatch(showAlert({
          message:"Repository added successfully",
          severity:"success"
        })); 
      } else {  
        // Error handling here
        const errorText=response.json();
        dispatch(showAlert({
          message:`Failed to add repository Error: ${errorText}`,
          severity:"error"
        }));  
      }  
    } catch (error) {  
      // Network or other errors 
      dispatch(showAlert({
        message:"There was a problem with the fetch operation",
        severity:"error"
      })); 
      console.error('There was a problem with the fetch operation:', error);  
    }  
    

    setAddDialogOpen(false);
    setAddRepo({  
      add_project: '',    
       repo_name: '',
      contributor_list:[]
    }); 
  };  


  //Edit form

  const [editRepo,setEditRepo]=useState({
    repo_name:'',
    edit_project:'',
    contributor_list: [], 
  })

  const [editContributor, setEditContributor] = useState({  
    edit_contributor_name: '',  
    edit_contributor_email: '',  
  });  

  const handleChangeEditContributor = (event) => {  
    const { name, value } = event.target;  
    setEditContributor(prev => ({  
      ...prev,  
      [name]: value,  
    }));  
  };  

  const handleEditContributor = () => {  
    // Add the new contributor to the contributor_list  
    if (editContributor.edit_contributor_name && editContributor.edit_contributor_email) {  
      setEditRepo(prev => ({  
        ...prev,  
        contributor_list: [...prev.contributor_list, editContributor],  
      }));  
      // Clear the temporary new contributor fields  
      setEditContributor({ edit_contributor_name: '', edit_contributor_email: '' });  
    } else {  
      // Handle the error case where name or email is missing  
      console.error('Name and email are required to add a contributor.');  
    }  
  };  

  // Function to remove a contributor from the list  
  const handleRemoveEditContributor = (indexToRemove) => {  
    setEditRepo(prev => ({  
      ...prev,  
      contributor_list: prev.contributor_list.filter((_, index) => index !== indexToRemove),  
    }));  
  }; 
  
  const handleCloseEditDialog=useCallback(()=>{
    setEditDialogOpen(false)
  },[])

  const handleEditRepo=(event)=>{
    const {name,value}=event.target;
    setEditRepo({
      ...editRepo,
      [name]:value,
    });
  };

  const handleOpenEditDialog = (row) => {  
    setEditRepo({  
      repo_name: row.repo_name,  
      edit_project: row.project_name || '',
      contributor_list: [],
    });  
    setEditDialogOpen(true);  
  };  

  const handleEditSubmit=async(event)=>{
    event.preventDefault();

    console.log("Edit repo :",editRepo);

    try {   
      const accessToken = sessionStorage.getItem('accessToken');
      if (!accessToken) {  
          console.error('No accessToken found in session storage');  
          return;  
      }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl+"editRepo";
      const response = await fetch(fetchUrl, {  
        method: 'PATCH',  
        headers: {  
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${accessToken}`, 
        },  
        body: JSON.stringify(editRepo),  
      });  
    
      if (response.ok) {  
        // Success logic here
        dispatch(showAlert({
          message:"Repository edit successfully",
          severity:"success"
        })); 
      } else {  
        // Error handling here
        dispatch(showAlert({
          message:"Failed to edit repository",
          severity:"error"
        }));  
      }  
    } catch (error) {  
      // Network or other errors 
      dispatch(showAlert({
        message:"There was a problem with the fetch operation",
        severity:"error"
      })); 
      console.error('There was a problem with the fetch operation:', error);  
    }  

    setEditDialogOpen(false);
    setEditRepo({
      repo_name:'',
      edit_project: '',
      contributor_list: [],
    });  

  }

  //Delete Repo

  const handleCloseDeleteButton=useCallback(()=>{
    setDeleteDialogOpen(false)
  },[]);

  const handleDeleteButton=async()=>{
    
    console.log("Deleting row:",selectedRowData);

    try {  
      // Send data to the API (replace 'your-api-endpoint' with the actual endpoint)  
      const accessToken = sessionStorage.getItem('accessToken');
      if (!accessToken) {  
          console.error('No accessToken found in session storage');  
          return;  
      } 
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl+"deleteRepo";
      const response = await fetch(fetchUrl, {  
        method: 'DELETE',  
        headers: {  
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${accessToken}`, 
        },  
        body: JSON.stringify(selectedRowData),  
      });  
    
      // Handle the API response  
      if (response.ok) {  
        // Success logic here
        dispatch(showAlert({
          message:"Repository deleted successfully",
          severity:"success"
        })); 
      } else {  
        // Error handling here
        dispatch(showAlert({
          message:"Failed to delete repository",
          severity:"error"
        }));  
      }  
    } catch (error) {  
      // Network or other errors 
      dispatch(showAlert({
        message:"There was a problem with the fetch operation",
        severity:"error"
      })); 
      console.error('There was a problem with the fetch operation:', error);  
    }  

    setDeleteDialogOpen(false);
    setSelectedRowData(null);
  }

  return (
    <div>
      <div style={{ marginTop: '5px', marginLeft: '60px', marginRight: '60px' }}>
        <div style={{ display: "flex", marginLeft: "-0.5%", gap: "3%" }}>
          <SelectSmall projects={uniqueProjects} onProjectChange={setSelectedProject} />
          <BasicTextFields onSearchChange={handleSearchChange} />
        </div>

        <div style={{ display: "flex", marginBottom: "15px", textAlign: "center", height: "350px" }}>
          <div style={{
            border: "2px solid #e6e6e6",
            position: "relative",
            width: "66.5%",
            paddingTop: "5px",
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Optum Sans",
            }}>
              <Typography variant="h7" style={{ fontWeight: !isMonthlyReport ? 'bold' : 'normal' }}>
                Daily Report
              </Typography>
              <ControlledSwitches onToggle={(isChecked) => setIsMonthlyReport(isChecked)} />
              <Typography variant="h7" style={{ fontWeight: isMonthlyReport ? 'bold' : 'normal' }}>
                Monthly Report
              </Typography>
            </div>
            {isMonthlyReport ? <MyResponsiveLine data={monthlyData} /> : <MyWeeklyLine data={weeklyData} />}
          </div>

          <div style={{
            marginLeft: "0.5%",
            paddingTop: "10px",
            border: "2px solid #e6e6e6",
            position: "relative",
            width: "33%",
          }}>
            <div style={{ fontFamily: "Optum Sans" }}>
              <b> Current Status </b>
            </div>
            <MyResponsivePie filteredData={filteredRows} />
          </div>
        </div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
          <TableContainer sx={{ maxHeight: '60%' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: 'bold' }}>Repository Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Repository Link</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Project</TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => handleSort('shown_vulnerabilities')}
                  >
                    Total Vulnerabilities
                    <UpDownArrowIconComponent
                      direction={sortConfig.key === 'shown_vulnerabilities' ? sortConfig.direction : null}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => handleSort('critical_vulnerabilities')}
                  >
                    Critical Severity
                    <UpDownArrowIconComponent
                      direction={sortConfig.key === 'critical_vulnerabilities' ? sortConfig.direction : null}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => handleSort('high_vulnerabilities')}
                  >
                    High Severity
                    <UpDownArrowIconComponent
                      direction={sortConfig.key === 'high_vulnerabilities' ? sortConfig.direction : null}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <IconButton onClick={() => {setAddDialogOpen(true);}} disabled={!isAdmin}>  
                      <AddIcon />  
                    </IconButton>  
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row, index) => (
                  <React.Fragment key={row.repo_id}>
                    <TableRow
                      key={row.sno}
                      onClick={() => handleRowClick(index)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          sx={{
                            transform: expandedRow === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.repo_name}</TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        <Link href={row.url} target="_blank" rel="noopener noreferrer">
                          {row.url}
                        </Link>
                      </TableCell>
                      <TableCell
                      sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.project_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.critical_vulnerabilities+row.high_vulnerabilities}</TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.critical_vulnerabilities}</TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.high_vulnerabilities}</TableCell>
                      <TableCell>
                      <Button onClick={(event)=>{event.stopPropagation(); setSelectedRowData(row); setDeleteDialogOpen(true)}} disabled={!isAdmin}><DeleteOutlineIcon /></Button>
                      </TableCell>
                      <TableCell>
                      <IconButton onClick={(event) => {event.stopPropagation();handleOpenEditDialog(row);}} disabled={!isAdmin}>  
                        <EditIcon />  
                      </IconButton>  
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Grid container spacing={4} justifyContent="space-between">
                              <Grid item xs={12} sm={6}>
                                <Typography variant="h8" component="h4" fontWeight="bold">
                                  Total Vulnerabilities: {row.total_vulnerabilities}
                                </Typography>
                                <Table size="small" aria-label="severity levels table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Critical Severity:
                                      </TableCell>
                                      <TableCell>{row.critical_vulnerabilities}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        High Severity:
                                      </TableCell>
                                      <TableCell>{row.high_vulnerabilities}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Moderate Severity:
                                      </TableCell>
                                      <TableCell>{row.medium_vulnerabilities}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        Low Severity:
                                      </TableCell>
                                      <TableCell>{row.low_vulnerabilities}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                  <Box
                                    sx={{
                                      border: '1px solid #e6e6e6',
                                      height: '180px',
                                      position: 'absolute',
                                      marginLeft: '30px',
                                      marginTop: '1px',
                                    }}
                                  >
                                    <BasicPie rowData={row} />
                                  </Box>
                                </Collapse>
                              </Grid>
                              <Grid item xs={12} justifyContent="flex-end" container>
                                <ButtonFilled row={row} />
                              </Grid>

                            </Grid>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </ React.Fragment>
                ))}
              </TableBody >
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <Dialog
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        aria-labelledby="repo-details-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="repo-details-dialog-title">Repository Vulnerabilities</DialogTitle>
        <DialogContent>
          {selectedRepoDetails && (
            <>
              <Typography variant="body1">
                <Link href={`${selectedRepoDetails.url}/security/dependabot`} target="_blank" rel="noopener noreferrer">
                  {`${selectedRepoDetails.url}/security/dependabot`}
                </Link>
              </Typography>
              {selectedRepoDetails.vulnerabilities.map((vulnerability, index) => (
                <div key={index}>
                  <p><strong>Description:</strong> {vulnerability.description}</p>
                  <p><strong>Severity:</strong> {vulnerability.severity}</p>
                  <p>
                    <strong>Package:</strong> {vulnerability.package.join(', ')}
                  </p>
                  <hr />
                </div>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>



      
      <Dialog
        open={editDialogOpen}
        onClose={() => {setEditDialogOpen(false);setSelectedRowData(null);}}
        aria-labelledby="edit-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="edit-dialog-title">Edit Repository</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off" onSubmit={handleEditSubmit} id="edit_repo_form">  
            <TextField  
              margin="dense"  
              id="repo_name"
              name="repo_name"
              label="Repository Name"  
              type="text" 
              fullWidth
              disabled
              variant="outlined"
              //value={(selectedRowData?.repo_name??'')}
              value={editRepo.repo_name}
              onChange={handleEditRepo}
            />
            <Autocomplete  
              id="edit_project"
              name="edit_project"
              freeSolo  
              options={uniqueProjects}  
              value={editRepo.edit_project}  
              onInputChange={(event, newInputValue) => {  
                setEditRepo(prev => ({  
                  ...prev,  
                  edit_project: newInputValue, 
                }));  
              }}  
              renderInput={(params) => (  
                <TextField  
                  {...params}  
                  label="Project"  
                  margin="dense"  
                  fullWidth  
                  variant="outlined"  
                />  
              )}  
            />
            <TextField  
              margin="dense"  
              id="edit_contributor_name"  
              name="edit_contributor_name"  
              label="Contributor Name"  
              type="text"  
              fullWidth  
              variant="outlined"  
              value={editContributor.edit_contributor_name}  
              onChange={handleChangeEditContributor}  
            />  
            <TextField  
              margin="dense"  
              id="edit_contributor_email"  
              name="edit_contributor_email"  
              label="Contributor Email"  
              type="email"  
              fullWidth  
              variant="outlined"  
              value={editContributor.edit_contributor_email}  
              onChange={handleChangeEditContributor}  
            />  
            <Button onClick={handleEditContributor} color="primary">  
              Add Contributor  
            </Button>  
              
            {/* Display the list of added contributors */}  
            {editRepo.contributor_list.map((contributor, index) => (  
              <div key={index} style={{ marginBottom: '10px' }}>  
                <Grid container alignItems="center" spacing={2}>  
                  <Grid item xs={5}>  
                    <TextField  
                      label="Contributor Name"  
                      value={contributor.edit_contributor_name}  
                      variant="outlined"  
                      fullWidth  
                      disabled  
                    />  
                  </Grid>  
                  <Grid item xs={5}>  
                    <TextField  
                      label="Contributor Email"  
                      value={contributor.edit_contributor_email}  
                      variant="outlined"  
                      fullWidth  
                      disabled  
                    />  
                  </Grid>  
                  <Grid item xs={2}>  
                    <Button  
                      variant="contained"  
                      color="secondary"  
                      onClick={() => handleRemoveEditContributor(index)}  
                    >  
                      Remove  
                    </Button>  
                  </Grid>  
                </Grid>  
              </div>  
            ))}
          </form>  
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>setEditDialogOpen(false)}><DeleteOutlineIcon /></Button> */}
          <Button onClick={handleCloseEditDialog}>Close</Button>
          <Button type="submit" form="edit_repo_form"
           color="primary">  
            Update
          </Button> 
        </DialogActions>
      </Dialog>



      <Dialog
        open={addDialogOpen}
        onClose={handleCloseAddDialog}
        aria-labelledby="add-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="add-dialog-title">Add Repository</DialogTitle>
        <DialogContent>
          <form noValidate id="add_repo_form" autoComplete="off" onSubmit={handleAddSubmit}> 
            <Autocomplete  
              id="add_project"
              name="add_project"
              freeSolo
              options={uniqueProjects}  
              value={addRepo.add_project}  
              onInputChange={(event, newInputValue) => {  
                setAddRepo(prev => ({  
                  ...prev,  
                  add_project: newInputValue, 
                }));  
              }}  
              renderInput={(params) => (  
                <TextField  
                  {...params}  
                  label="Project"  
                  margin="dense"  
                  fullWidth
                  required
                  variant="outlined"  
                />  
              )}  
            />  
            <TextField  
              margin="dense"  
              id="repo_name"
              name="repo_name"  
              label="Repository Name"  
              type="text"
              required 
              fullWidth
              variant="outlined"
              value={addRepo.repo_name}
              onChange={handleAddRepo}
              // Add an onChange handler if you want to capture input
            />  
            <TextField    
              margin="dense"    
              id="repo_link" 
              name="repo_link"    
              label="Repository Link"    
              type="text"  
              required  
              fullWidth    
              variant="outlined"    
              value={addRepo.repo_link}    
              onChange={handleAddRepo}
            />  
            <TextField  
              margin="dense"  
              id="add_contributor_name"  
              name="add_contributor_name"  
              label="Contributor Name"  
              type="text"  
              fullWidth  
              variant="outlined"  
              value={addContributor.add_contributor_name}  
              onChange={handleChangeAddContributor}  
            />  
            <TextField  
              margin="dense"  
              id="add_contributor_email"  
              name="add_contributor_email"  
              label="Contributor Email"  
              type="email"  
              fullWidth  
              variant="outlined"  
              value={addContributor.add_contributor_email}  
              onChange={handleChangeAddContributor}  
            />  
            <Button onClick={handleAddContributor} color="primary">  
              Add Contributor  
            </Button>  
              
            {/* Display the list of added contributors */}  
            {addRepo.contributor_list.map((contributor, index) => (  
              <div key={index} style={{ marginBottom: '10px' }}>  
                <Grid container alignItems="center" spacing={2}>  
                  <Grid item xs={5}>  
                    <TextField  
                      label="Contributor Name"  
                      value={contributor.add_contributor_name}  
                      variant="outlined"  
                      fullWidth  
                      disabled  
                    />  
                  </Grid>  
                  <Grid item xs={5}>  
                    <TextField  
                      label="Contributor Email"  
                      value={contributor.add_contributor_email}  
                      variant="outlined"  
                      fullWidth  
                      disabled  
                    />  
                  </Grid>  
                  <Grid item xs={2}>  
                    <Button  
                      variant="contained"  
                      color="secondary"  
                      onClick={() => handleRemoveContributor(index)}  
                    >  
                      Remove  
                    </Button>  
                  </Grid>  
                </Grid>  
              </div>  
            ))}  
          </form>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>Close</Button>
          <Button type="submit" form="add_repo_form"
          color="primary">  
            Add
          </Button> 
        </DialogActions>
      </Dialog>




      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteButton}
        aria-labelledby="delete-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="edit-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">  
            Are you sure you want to delete "{selectedRowData?.repo_name}"?  
          </Typography> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteButton}>No</Button>
          <Button onClick={handleDeleteButton} 
          color="primary">  
            Yes
          </Button> 
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DataMonitoring;  
