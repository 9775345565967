import { configureStore } from "@reduxjs/toolkit";
import UserInfoReducer from "../features/uiSlice";
import alertReducer from "../features/alertSlice";

export const store = configureStore({
  reducer: {
    userDetails: UserInfoReducer,
    alert:alertReducer
  },
});
