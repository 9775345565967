import React, { createContext, useContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';


const NotifyContext = createContext();

export const useNotify = () => {
    const context = useContext(NotifyContext);
    if (!context) {
        throw new Error('useNotify must be used within a NotifyProvider');
    }
    return context;
}

export const NotifyProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const notify = (message, newSeverity = "info") => {
        setMessage(message);
        setSeverity(newSeverity);
        setOpen(true);
    }

    // const closeNotify = () => {
    //     setOpen(false);
    // }

    return (
        <NotifyContext.Provider value={notify}>
            {children}
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'centre' }}>
                <Alert variant='filled' severity={severity}>{message}</Alert>
            </Snackbar>
        </NotifyContext.Provider>
    )
}