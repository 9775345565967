import React from 'react'
import { ReactComponent as Img1 } from "./img1.svg";
import "./LoginPage.css";
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
const LoginPage=({handleLogin})=> {
  return (
    <>
    <LoginHeader />
    <div className='login-container'>
        <div className='login-box'>
            <h2 className='welcome_text'>Welcome</h2>
            <p>Login to access the DevOps Monitor</p>
            <button className='login-btn' onClick={handleLogin}>Log in</button>
        </div>
        <div className='illustration'>
            <Img1 />
        </div>
    </div>
    <LoginFooter />
    </>
  )
}

export default LoginPage