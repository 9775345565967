import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function BasicPie({ rowData }) {
  const pieChartData = [
    {
      id: 0,
      value: rowData.critical_vulnerabilities,
      label: 'Critical',
      color: '#e60000',
    },
    {
      id: 1,
      value: rowData.high_vulnerabilities,
      label: 'High',
      color: '#ff8000',
    },
    {
      id: 2,
      value: rowData.medium_vulnerabilities,
      label: 'Moderate',
      color: '#ffcc00',
    },
    {
      id: 3,
      value: rowData.low_vulnerabilities,
      label: 'Low',
      color: '#ffff99',
    },
  ];

  return (
    <PieChart
      series={[
        {
          data: pieChartData,
        },
      ]}
      width={400}
      height={175}
    />
  );
}  
