import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../features/alertSlice";
import { ReactComponent as OptumLogoSvg } from "../assets/optum_logo.svg";
import Alert from '@mui/material/Alert';
import SelectnoLabel from "./Data Monitoring/selectFn-nolabel";
import UserProfileButton from "./Data Monitoring/UserProfileButton";

const Header = ({selectedComponent,onComponentChange}) => {

    const dispatch=useDispatch();
    const alert=useSelector((state)=>state.alert);

    useEffect(()=>{
        if(alert.show){
            const timer=setTimeout(()=>{
                dispatch(hideAlert());
            },3000);
            return()=>clearTimeout(timer);
        }
    },[alert.show,dispatch]);

    return (
        <div>
            <div
                style={{
                    width: "100vw",
                    height: "100px",
                    backgroundColor: "#FAF8F2",
                    position: "fixed",
                    display: "flex",
                    zIndex: 1000,
                    alignItems: "center", 
                    justifyContent: "space-between",
                    padding: "0 40px",
                }}
            >
                <div style={{ display: "flex", alignItems: "center",flexDirection:"column" }}>
                    <div>
                        {" "}
                        <OptumLogoSvg
                            style={{
                                marginTop: "5px",
                                backgroundColor: "#F16231s",
                                fill: "#F16231",
                            }}
                        />
                    </div>

                    <label
                        style={{
                            color: "#002677",
                            fontSize: "18px",
                            fontWeight: 700,
                        }}
                    >
                        OHFT Innovation
                    </label>
                </div>
                <div
                    style={{
                        marginTop: "50px",
                        color: "#002677",
                        fontSize: "20px",
                        display:"flex",
                        alignItems:"center",
                        flexShrink: 0,
                    }}
                >
                    {alert.show && (  
                        <Alert variant="filled" severity={alert.severity}>  
                        {alert.message}  
                        </Alert>  
                    )}  
                    <div style={{position: 'relative',marginRight:"10px",width:"auto"}}>
                        <SelectnoLabel onComponentChange={onComponentChange} />
                    </div>
                    <div style={{marginRight:"10px",width:"auto"}}>
                        <UserProfileButton />
                    </div>
                    <div style={{marginRight:"30px"}}>
                    </div>              
                </div>
            </div>

            <div style={{ display: "flex" }}>
                <div
                    style={{
                        width: "85vw",
                        marginTop: "10px",
                        marginLeft: "30px",
                        height: "30px",
                    }}
                ></div>
                <div
                    style={{
                        marginTop: "10px",
                        marginRight: "30px",
                        height: "30px",
                    }}
                ></div>
            </div>
        </div>
    );
};

export default Header;  
