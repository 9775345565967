import React from "react";
import { ReactComponent as OptumLogoSvg } from "../../assets/optum_logo.svg";

const LoginHeader = () => {

    return (
        <div>
            <div
                style={{
                    width: "100vw",
                    height: "108px",
                    backgroundColor: "#FAF8F2",
                    position: "fixed",
                    display: "flex",
                    zIndex: 1000,
                }}
            >
                <div style={{ marginTop: "10px", marginLeft: "40px" }}>
                    <div>
                        {" "}
                        <OptumLogoSvg
                            style={{
                                marginTop: "5px",
                                backgroundColor: "#F16231s",
                                fill: "#F16231",
                                flexShrink: 0,
                            }}
                        />
                    </div>

                    <label
                        style={{
                            color: "#002677",
                            fontSize: "18px",
                            fontWeight: 700,
                        }}
                    >
                        OHFT Innovation
                    </label>
                </div>
            </div>

            <div style={{ display: "flex" }}>
                <div
                    style={{
                        width: "85vw",
                        marginTop: "10px",
                        marginLeft: "30px",
                        height: "30px",
                    }}
                ></div>
                <div
                    style={{
                        marginTop: "10px",
                        marginRight: "30px",
                        height: "30px",
                    }}
                ></div>
            </div>
        </div>
    );
};

export default LoginHeader;  
