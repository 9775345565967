import React from "react";

const LoginFooter = () => {
    
    return (
        <div>
            <div
                style={{
                    width: "100vw",
                    height: "92px",
                    backgroundColor: "#FAF8F2",
                    position: "fixed",
                    bottom:0,
                    display: "flex",
                    zIndex: 1000,
                }}
            >
                <div style={{ marginTop: "40px", marginLeft: "40px" }}>
                    <label
                        style={{
                            fontSize: "16px",
                            fontWeight: 400,
                        }}
                    >
                        © 2023 Optum Inc. All rights reserved.  
                    </label>
                </div>
            </div>
        </div>
    );
};

export default LoginFooter;  
