import { ResponsivePie } from '@nivo/pie';

const AppRegProjectStatsPie = ({ statData, pieName }) => {
    const hslColorCode = [0, 36, 72, 108, 144, 180, 216, 252, 288, 324];
    var determinant = 0;

    const pieChartData = statData.map((row) => {
        determinant++;
        return {
            id: row.ProjectName,
            label: row.ProjectName,
            value: row.CredentialCount,
            color: "hsl(" + hslColorCode[determinant] + ", 70%, 50%)"
        };
    });

    const CenterText = () => (layerProps) => {
        const { centerX, centerY } = layerProps;
        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: "9px",
                    fontWeight: "500"
                }}
            >
                {pieName}
            </text>
        );
    };

    return (
        <ResponsivePie
            data={pieChartData}
            margin={{ top: 40, right: 60, bottom: 40, left: 60 }}
            innerRadius={0.7}
            padAngle={1}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={{ scheme: 'set3' }}
            borderColor={{
                from: 'color', modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            enableArcLinkLabels={false}
            layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenterText("")]}
        />
    )
}

export default AppRegProjectStatsPie;