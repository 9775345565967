import { ResponsiveLine } from '@nivo/line'

const monthOrder = {  
  'January': 1,  
  'February': 2,  
  'March': 3,  
  'April': 4,  
  'May': 5,  
  'June': 6,  
  'July': 7,  
  'August': 8,  
  'September': 9,  
  'October': 10,  
  'November': 11,  
  'December': 12  
};  

export const transformDataForMonthlyChart = (apiData) => {  
    const transformedData = [  
      { id: "Critical", color: "hsl(0, 100%, 50%)", data: [] },  
      { id: "High", color: "hsl(30, 100%, 50%)", data: [] },   
    ];  

    // Temporary object to hold the sum of vulnerabilities for each type and month  
    const vulnerabilitiesSum = {};  
    
    // Iterate over each product and month to sum up the vulnerabilities  
    Object.keys(apiData).forEach((productKey) => {  
      Object.keys(apiData[productKey]).forEach((month) => {  
        const vulnerabilities = apiData[productKey][month];  
    
        // Initialize the sum object for this month if it does not exist  
        if (!vulnerabilitiesSum[month]) {  
          vulnerabilitiesSum[month] = {  
            Critical: 0,  
            High: 0,   
          };  
        }  
    
        // Sum up the vulnerabilities for each type  
        if (Object.keys(vulnerabilities).length !== 0) {  
          vulnerabilitiesSum[month].Critical += vulnerabilities.total_critical_vulnerability || 0;  
          vulnerabilitiesSum[month].High += vulnerabilities.total_high_vulnerability || 0;  
        }  
      });  
    });  

    // Convert the sums into the data format required by the chart  
    Object.keys(vulnerabilitiesSum).forEach((month) => {  
      transformedData.forEach((vulnerabilityType) => {  
        vulnerabilityType.data.push({  
          x: month,  
          y: vulnerabilitiesSum[month][vulnerabilityType.id],  
        });  
      });  
    });  

    // Sort the data points for each vulnerability type by month   
    transformedData.forEach((vulnerabilityType) => {  
      vulnerabilityType.data.sort((a, b) => {  
        return monthOrder[a.x] - monthOrder[b.x];  
      });  
    });  
    
    return transformedData;  
  };  
  


const MyResponsiveLine = ({ data }) => {

    return ( <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 110, bottom: 85, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        colors={(serie) => {
            switch (serie.id) {
                case "Critical":
                    return "#e60000";
                case "High":
                    return "#ff8000";
                default:
                    return "blue";
            }
        }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />)
}


export default MyResponsiveLine;