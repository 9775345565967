import { useState,useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';    
import infoData from './infoData.json';
import { useUserRole } from '../LoginPage/UserRoleContext';

    
const Instruction = () => {  
 const [data,setData]=useState({});
 const {userName}=useUserRole();
 
 useEffect(() => {  
    setData(infoData);  
  }, []);
   
  const renderTextWithLinks = (text) => {  
    const urlRegex = /(https?:\/\/[^\s]+)/g;  
    const parts = text.split(urlRegex);  
  
    return parts.map((part, index) => {  
      if (urlRegex.test(part)) {  
        return (  
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">  
            {part}  
          </a>  
        );  
      }  
      return <span key={index}>{part}</span>;  
    });  
    };  
  
  return (    
    <>    
      <Box display="flex" flexDirection="column" minHeight="100vh" >    
        <Box bgcolor="#EEF4FF" pt={6} pb={3} px={10} sx={{margin:0}}>  
          <Container maxWidth={false} >    
            <Typography variant="h5" fontWeight={700} fontSize="24px" gutterBottom sx={{color:'#00184D'}} >  
              Welcome {userName}
            </Typography>  
            <Typography   gutterBottom sx={{color:'#00184D'}} > 
              You can navigate to different section from drop down above
            </Typography> 

            <Typography variant="h5" fontWeight={700} fontSize="24px" gutterBottom sx={{color:'#00184D',mt:'25px'}} >  
              Getting Started 
            </Typography> 

            <Typography   gutterBottom sx={{color:'#00184D',maxWidth: '70%', mt:'12px'}} > 
                DevOps Orchestrator utlities:
            </Typography>

            <Typography   gutterBottom sx={{color:'#00184D',maxWidth: '70%', mt:'12px'}} > 
             <strong> Vulnerability </strong> : This feature assists in identifying security vulnerabilities in your repository. It provides a direct link to the repository for easy navigation.
            </Typography> 

            <Typography   gutterBottom sx={{color:'#00184D',maxWidth: '70%', mt:'12px'}} > 
            <strong> Pull Request </strong> : This section displays all the pending pull requests (PRs) of your repositories, sorted in descending order from most to least pending PRs. It includes direct links to the repository and individual PRs, making it easy to navigate and manage them.
            </Typography> 

            <Typography   gutterBottom sx={{color:'#00184D',maxWidth: '70%', mt:'12px'}} > 
            <strong> Orchestrator </strong> : This section provides the utility to create self-service proof of concept infrastructure in our Azure Incubator R&D subscription. Just fill out a a few entries and an Azure resource group and infrastructure will be created and integrated for you. 
            </Typography> 
          </Container>  
        </Box>  


        {/* Info/Other Section */}  
        <Box px={10} pb={10} sx={{margin:0}}>
          <Container maxWidth={false}>  
            {Object.keys(data).map((sectionKey, index) => (  
              <Box p={2} key={index}>  
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  {sectionKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}  
                </Typography>  
                <ul>  
                  {data[sectionKey].map((item, idx) => (  
                    <li key={idx}>{renderTextWithLinks(item.text)}</li>  
                  ))}  
                </ul>  
              </Box>  
            ))}  
          </Container>  
        </Box>
      </Box>  
    </>  
  );  
};  
    
export default Instruction;  
